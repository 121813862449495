<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الإشعارات</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">الإشعارات</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="my-invoices">
                                <div class="inner-container clearfix">
                                    <div class="invoice-box">
                                        <div class="table-responsive">
                                            <table
                                                id="notifications-table"
                                                class="
                                                    table
                                                    table-striped
                                                    table-bordered
                                                    table-hover
                                                "
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>العنوان</th>
                                                        <th>محتوى الرسالة</th>
                                                        <th>التاريخ</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/plugins/datatables";
import $ from "jquery";

export default {
    name: "my.notifications",
    data() {
        return {
            table: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(($this) => {
            $this.$store.dispatch("setUnreadNotes");
        });
    },
    mounted() {
        this.prepareTable();
    },
    methods: {
        prepareTable() {
            this.table = $("#notifications-table")
                .DataTable({
                    ajax: {
                        url:
                            process.env.VUE_APP_API_BASE_URL +
                            "/my-notifications",
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                        beforeSend: () => {
                            this.$loading.show();
                        },
                    },
                    serverSide: true,
                    stateSave: true,
                    columns: [
                        { data: "id" },
                        { data: "title" },
                        { data: "body" },
                        { data: "created_at" },
                    ],
                })
                .on("draw", () => {
                    this.$loading.hide(0);
                    this.$store.dispatch("setUnreadNotes");
                });
        },
    },
};
</script>

<style>
@import "https://cdn.datatables.net/1.10.22/css/jquery.dataTables.min.css";
</style>